import React from 'react'
import styled from 'styled-components';
import Layout from '../components/layout'
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import ProductItem from '../components/products/product-item';
import { useSelector, useDispatch } from 'react-redux'
import { convertShopifyId } from '../utils/utils';
import { addToCart, toggleShowCart, updateCartCount } from '../state/app';

const Container = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 20px;
`
const Banner = styled.img`
    width: 100%;
`;
const Title = styled.h1`
    text-align: center;
`
const Subtitle = styled.div`
    font-family: "Muli";
    margin: 0 auto;
    text-align: center;
    margin-bottom: 40px;
    max-width: 600px;
`
const ProductsContainer = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  flex: 1;

  @media (max-width: 980px) {
    grid-template-columns: 33% 33% 33%;

  }

  @media (max-width: 750px) {
    grid-template-columns: 50% 50%;
  }
`

const SalePage = ({ data }) => {

    const {collection} = data
    const dispatch = useDispatch()
    const shoppingCart = useSelector(state => state.app.shoppingCart)

    const handleAddToCart = product => {
        let productsToReturn = shoppingCart
    
        //Check if the product is already in the cart
        const obj = shoppingCart.find(el => el.shopifyId === product.shopifyId)
    
        if (obj) {
          //find the index and adjust the count
          let index = shoppingCart.indexOf(obj)
          productsToReturn[index].count = productsToReturn[index].count + 1
    
          //Dispatch new cart with updated counts to state
          dispatch(updateCartCount(productsToReturn))
          dispatch(toggleShowCart(true))
        } else {
          const productWithFields = {
            ...product,
            count: 1,
            variantId: convertShopifyId(product.variants[0].shopifyId)
            
          }
          dispatch(addToCart(productWithFields))
          dispatch(toggleShowCart(true))
        }
    
        //Remove the cart after 2 seconds
        setTimeout(() => {
          dispatch(toggleShowCart(false))
        }, 2000)
    }

    return (
        <Layout>
            <SEO title="On Sale" />
            <Container>
                <Title>On Sale</Title>
                {/* <Subtitle dangerouslySetInnerHTML={{ __html: collection.descriptionHtml}} /> */}
                <ProductsContainer>
                    {collection.products.map((product, index) => (
                    <ProductItem
                        key={index}
                        product={product}
                        addToCart={handleAddToCart}
                    />
                    ))}
              </ProductsContainer>
            </Container>
        </Layout>
    )
}

export default SalePage

export const SalePageQuery = graphql`
    query SaleQuery {
        collection: shopifyCollection(title: {eq: "On Sale"}) {
            title
            descriptionHtml
            
            products {
                title
                shopifyId
                availableForSale
                handle
                description
                priceRange {
                maxVariantPrice {
                    amount
                }
                minVariantPrice {
                    amount
                }
                }
                images {
                    originalSrc
                }
                variants {
                    shopifyId
                    title
                    compareAtPrice
                    price
                    availableForSale
                    image {
                        originalSrc
                    }
                }
            }
            }
    }
`